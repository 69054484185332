'use client';

import { useSetArticleId } from '@haaretz/s-atoms/articleId';
import { useSetAuthors } from '@haaretz/s-atoms/authors';
import { useMutatorsClientTeasersOnPage } from '@haaretz/s-atoms/clientTeasersOnPage';
import { useSetCookie } from '@haaretz/s-atoms/cookie';
import { useSetPage } from '@haaretz/s-atoms/page';
import { useSetPageStatusToken } from '@haaretz/s-atoms/pageStatusToken';
import { useSetPageType } from '@haaretz/s-atoms/pageType';
import { useSetPaywallType } from '@haaretz/s-atoms/paywallType';
import { useSetPreviewId } from '@haaretz/s-atoms/previewId';
import { useSetRenderingKind } from '@haaretz/s-atoms/renderingKind';
import { useSetTags } from '@haaretz/s-atoms/tags';
import useUser from '@haaretz/s-atoms/user';
import usePathChanged from '@haaretz/s-common-hooks/usePathChanged';
import getK5aMeta from '@haaretz/s-page-utils/getK5aMeta';
import useBi from '@haaretz/s-use-bi';
import React from 'react';

import type { BiDataOverrides, Breadcrumbs } from '@haaretz/s-data-structure-types';
import type { CommonPageLayoutFragment } from '@haaretz/s-fragments/CommonPageLayout';
import type { SeoDataFragment } from '@haaretz/s-fragments/SeoData';
import type { PageType, PlatformType, RenderingKind } from '@haaretz/s-types';

const requestBiData: BiDataOverrides = {};

interface CommonUtilsProps {
  pageType: PageType;
  previewId: string | null;
  renderingKind: RenderingKind;
  pageContentId: CommonPageLayoutFragment['contentId'];
  title: SeoDataFragment['metaTitle'];
  platform: PlatformType;
  breadcrumbs: Breadcrumbs | null;
}

export default function CommonUtils({
  pageContentId,
  pageType,
  previewId,
  renderingKind,
  title,
  breadcrumbs,
  platform,
}: Readonly<CommonUtilsProps>) {
  const biRequest = useBi('pageview');
  const user = useUser('cookieValue');

  const setPageType = useSetPageType();
  const setPagePaywallLevel = useSetPaywallType();
  const setRenderingKind = useSetRenderingKind();
  const setAuthors = useSetAuthors();
  const setTags = useSetTags();
  const setPage = useSetPage();
  const setCookie = useSetCookie();
  const setPreviewId = useSetPreviewId();
  const setArticleId = useSetArticleId();
  const setPageStatusToken = useSetPageStatusToken();
  const { clearClientTeasers } = useMutatorsClientTeasersOnPage();

  React.useEffect(() => {
    setPageType(pageType);
  }, [pageType, setPageType]);

  React.useEffect(() => {
    setPagePaywallLevel('free');
  }, [setPagePaywallLevel]);

  React.useEffect(() => {
    setRenderingKind(renderingKind);
  }, [renderingKind, setRenderingKind]);

  React.useEffect(() => {
    setAuthors(null);
  }, [setAuthors]);

  React.useEffect(() => {
    setTags(null);
  }, [setTags]);

  React.useEffect(() => {
    setPageStatusToken(null);
  }, [setPageStatusToken]);

  React.useEffect(() => {
    clearClientTeasers();
  }, [clearClientTeasers]);

  React.useEffect(() => {
    setPage({
      pageContentId,
      articleSectionId: undefined,
    });
  }, [pageContentId, setPage]);

  React.useEffect(() => {
    if (pageContentId) {
      window._tfa = window._tfa || [];

      window._tfa.push({ notify: 'newPageLoad' });
    }
  }, [pageContentId]);

  React.useEffect(() => {
    window.k5aMeta = {
      ...getK5aMeta({
        platform,
        pageType,
        breadcrumbs:
          /* istanbul ignore next */
          breadcrumbs?.map(breadcrumb => breadcrumb?.body || '') || [],
        title,
        renderingKind,
        user,
      }),
    };

    const spaReadyEvent: CustomEvent<never> = new CustomEvent('K5A:SpaReady');

    document.dispatchEvent(spaReadyEvent);
  }, [breadcrumbs, pageType, platform, renderingKind, title, user]);

  React.useEffect(() => {
    setPreviewId(previewId);
  }, [previewId, setPreviewId]);

  React.useEffect(() => {
    setArticleId(null);
  }, [setArticleId]);

  React.useEffect(() => {
    if (!!pageContentId && !!biRequest) {
      const timeout = setTimeout(() => biRequest(requestBiData), 500);

      return () => timeout && clearTimeout(timeout);
    }

    return undefined;
  }, [biRequest, pageContentId]);

  /* istanbul ignore next */
  const onPathChangedCb = React.useCallback(() => {
    setCookie();
  }, [setCookie]);

  usePathChanged(onPathChangedCb);

  return null;
}
