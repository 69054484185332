'use client';

import useBi from '@haaretz/s-use-bi';
import React from 'react';

const MINUTE = 1000 * 60;
// The number of minutes between refreshes
const INTERVAL = 5;

interface AutoRefreshProps {
  intervalOverride?: number;
}

export default function AutoRefresh({ intervalOverride }: AutoRefreshProps) {
  const biAction = useBi('action');

  const sendBiAction = React.useCallback(() => {
    biAction({
      feature: 'Auto refresh page',
      feature_type: 'Content',
      action_id: 4,
    });
  }, [biAction]);

  React.useEffect(() => {
    window.__HTZ = window.__HTZ || {};

    const refreshFn = () =>
      setTimeout(
        function refreshWindow() {
          sendBiAction();

          if (window.location.search.includes('page=')) {
            const currentUrl = new URL(window.location.href);
            const currentSearchParams = new URLSearchParams(currentUrl.search);

            currentSearchParams.delete('page');

            currentUrl.search = currentSearchParams.toString();

            window.location.href = currentUrl.href;
          } else {
            window.location.reload();
          }
        },
        MINUTE * (intervalOverride || INTERVAL)
      );

    const startRefresh = () => {
      const refreshTimer = refreshFn();
      window.__HTZ.cancelRefresh = function cancelRefresh() {
        clearTimeout(refreshTimer);
      };
    };

    try {
      if (typeof window.__HTZ.cancelRefresh === 'function') {
        window.__HTZ.cancelRefresh();
      }

      if (window.matchMedia('(min-width: 37.5em)').matches) {
        startRefresh();
        window.__HTZ.continueRefreshFn = startRefresh;
      }
    } catch (err) {
      console.error(err);
    }

    return () => {
      if (typeof window.__HTZ.cancelRefresh === 'function') {
        window.__HTZ.cancelRefresh();
      }
    };
  }, [intervalOverride, sendBiAction]);

  return null;
}
